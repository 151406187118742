.marquee {
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 4rem;
}

.content {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 100%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &.left {
    animation-name: slideLeft;
  }

  &.right {
    animation-name: slideRight;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% - 4rem));
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(4rem);
  }
}
