@use "styles" as *;

.field_group {
  margin-bottom: $lg;

  h3,
  h4,
  h5,
  h6 {
    display: block;
    margin-bottom: 1rem;
    font-size: $sm;
    font-weight: 500;
  }

  > p {
    margin-top: 1rem;
    font-size: 1.6rem;
    color: $red;
  }
}
