@use "styles" as *;

.button {
  border-radius: 1rem;
  padding: 1rem $sm;
  border-radius: 100rem;
  font-weight: 500;
  font-size: $sm;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:disabled {
    filter: brightness(90%);
    cursor: not-allowed;
  }

  &.loading {
    filter: initial;
    pointer-events: none;
    > .btnText {
      visibility: hidden;
    }
  }
}

// variant
.blue {
  background: $blue;
  color: $white;
}

// align
.left {
  margin-right: auto;
}

.right {
  margin-left: auto;
}

.center {
  margin-right: auto;
  margin-left: auto;
}

// loading
.spinner {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  color: inherit;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top-color: currentColor;
  animation: spinner 0.6s cubic-bezier(0.5, 0.3, 0.9, 0.9) infinite;
}

@keyframes spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
