@use "variables" as v;

@mixin tablet {
  @media only screen and (min-width: v.$tablet-bp) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: v.$desktop-bp) {
    @content;
  }
}
