.filter_container {
  display: inline-block;
  position: relative;
  line-height: 0;
  background: #ffffff;
}
.filter_container::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  mix-blend-mode: none;
  background: #faaa00;
  opacity: 0.13;

  & > .image {
    -webkit-filter: brightness(100%) contrast(100%) grayscale(0%)
      hue-rotate(0deg) invert(0%) opacity(100%) saturate(100%) sepia(0%);
    filter: brightness(100%) contrast(100%) grayscale(0%) hue-rotate(0deg)
      invert(0%) opacity(100%) saturate(100%) sepia(0%);
    mix-blend-mode: none;
  }
}

.image {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s;
}

.loaded {
  opacity: 1;
}
