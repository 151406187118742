@use "styles" as *;
@use "sass:map";

:root {
  --header-height: 6rem;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: map.get($z-index, "floating");
}

.nav {
  height: var(--header-height);
  // background: $white;
  backdrop-filter: blur(10px);
  background: transparent;
  padding: 0 $xs;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $blue;
  font-weight: 500;

  @include desktop() {
    padding: 0 $xl;
  }

  &__right {
    display: flex;
    gap: 3rem;
  }
}
.white {
  color: $white;
}
