@use "styles" as *;
@forward "custom/normalize";
@forward "custom/form";
@import-normalize;

html {
  font-size: 52%;
  @include tablet {
    font-size: 56.25%;
  }
  @include desktop {
    font-size: 62.5%; //1rem = 16px
  }
}

body {
  font-size: $xs;
  letter-spacing: -0.02em;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  color: $black;
  min-width: 320px;
}
