@use "variables" as v;
@use "mixins";

%center {
  width: 100%;
  max-width: v.$max-width;
  margin: 0 auto;
  padding-left: v.$xs;
  padding-right: v.$xs;

  @include mixins.desktop() {
    padding-left: v.$xl;
    padding-right: v.$xl;
  }
}
