@use "styles" as *;

.hooking {
  background: $pale;
  padding: $xxl 0;
  @include tablet() {
    padding-top: 12rem;
    padding-bottom: 14rem;
  }
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $lg;

  li {
    @extend %box-shadow;
    flex-shrink: 1;
    width: 28rem;
    height: 30rem;
    word-break: keep-all;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    gap: $md;
    background: $pale;
    padding: $lg $md 0;
    border-radius: $sm;
    font-size: $sm;
    font-weight: 500;
  }

  @include tablet() {
    flex-direction: row;
    gap: $xs;
  }
  @include desktop() {
    gap: $xl;
  }
}
