@use "styles" as *;

.reviewcard {
  @extend %box-shadow;
  border-radius: $xs;
  width: 36rem;
  height: 36rem;
  background: $white;
  padding: $sm;
  margin-bottom: $md;
  margin-top: $xs;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: $md;
}

.footer {
  display: flex;
  align-self: flex-end;
}
.nickname {
  font-weight: 500;
}
.date {
}
