@use "styles" as *;

.reviews {
  padding: $xxl 0 $xs;
  @include tablet() {
    padding: 14rem 0 $xs;
  }
}

.photo {
  border-radius: $xs;
  overflow: hidden;
  width: max-content;
}
