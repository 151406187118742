@use "styles" as *;

.faq {
  @extend %center;
  padding-top: $xxl;
  padding-bottom: $xxl;
  @include tablet() {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
}

.question {
  @extend %noselect;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: $sm;
  font-weight: 500;
  padding: $xs $md;
  background-color: $ivory;
  border-radius: 100rem;
  margin-top: $xs;

  svg {
    transition: transform 0.3s ease-in-out;
  }
}
.active_question {
  svg {
    display: block;
    transform: rotate(135deg);
  }
}

.answer {
  overflow: hidden;
  & > div {
    padding: $xs $md;
  }
}
