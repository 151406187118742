@use "styles" as *;

.footer {
  background: $blue;
  color: $white;
}

.copy {
}

.wrapper {
  padding: $md $xs;

  @include desktop() {
    padding: $lg $xl;
  }
}
