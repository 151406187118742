@use "styles" as *;

.banner {
  background: $pink;
  color: $white;
  padding: 1.4rem 0;
  user-select: none;
  cursor: pointer;
}

.element {
  font-weight: 500;
}
