@use "styles" as *;

.hero {
  margin-top: var(--header-height);
  display: flex;
  gap: $sm;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  padding: 0;
  position: relative;

  h1 {
    word-break: keep-all;
    font-size: 3.6rem;
    color: $blue;
    span {
      display: inline-block;
    }
    @include desktop() {
      font-size: $lg;
    }
  }
}

.circle {
  width: 90vw;
  height: 90vw;
  max-width: 44rem;
  max-height: 44rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: linear-gradient(to bottom, $light-green, $light-purple);

  svg {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.ship_wrapper {
  position: absolute;
  transform: translate(-50%, -50%);
  bottom: 10%;
  left: 50%;
  display: block;
  z-index: 4;
}
.ship {
  display: block;
  z-index: 6;
  width: 10.75rem;
  height: 4.25rem;
  transform-origin: center center;
  animation: shipFloat 1.6s linear infinite;

  > span {
    // bow
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 10.75rem;
    height: 4.25rem;
    border: 3px solid $blue;
    border-bottom-right-radius: 5rem;
    border-bottom-left-radius: 3.5rem;
    background-color: $blue;
    z-index: 1;

    &.mast {
      left: 3.75rem;
      bottom: 4.25rem;
      width: 3px;
      height: 10.625rem;
      border: none;
      border-radius: 0;
      background-color: $blue;
    }

    &.jib {
      left: 2.625rem;
      bottom: 7.125rem;
      width: 0;
      height: 0;
      border: none;
      border-radius: 0;
      background-color: transparent;
      border-bottom: 7.75rem solid $blue;
      border-left: 2.5rem solid transparent;
      border-right: 2.5rem solid transparent;
      transform: rotate(-16.5deg);
      border-bottom-right-radius: 5px;
    }
    &.jib_inner {
      left: 2.625rem;
      bottom: 6.875rem;
      width: 0;
      height: 0;
      border: none;
      border-radius: 0;
      background-color: transparent;
      border-bottom: 7.75rem solid #fff;
      border-left: 2.5rem solid transparent;
      border-right: 2.5rem solid transparent;
      transform: rotate(-17.5deg) scale(0.85);
      border-bottom-right-radius: 3px;
      z-index: 2;
    }

    &.mainsail {
      left: -1px;
      bottom: 7.5rem;
      width: 0;
      height: 0;
      border: none;
      border-radius: 0;
      background-color: transparent;
      border-bottom: 6rem solid $blue;
      border-left: 3.875rem solid transparent;
      border-right: 0 solid transparent;
      border-bottom-left-radius: 5px;
    }

    &.mainsail_inner {
      left: 1px;
      bottom: 7.25rem;
      width: 0;
      height: 0;
      border: none;
      border-radius: 0;
      background-color: transparent;
      border-bottom: 6rem solid #fff;
      border-left: 3.875rem solid transparent;
      border-right: 0 solid transparent;
      border-bottom-left-radius: 3px;
      transform: scale(0.8);
      z-index: 2;
    }
  }
}

@keyframes shipFloat {
  0% {
    transform: translateY(0) rotate(0);
  }
  25% {
    transform: translateY(0) rotate(-10deg);
  }
  40% {
    transform: translateY(4px) rotate(-10deg);
  }
  85% {
    transform: translateY(1px) rotate(10deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
