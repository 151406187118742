@use "styles" as *;

.hero {
  height: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("https://res.cloudinary.com/dftuawd1d/image/upload/v1675433466/harang/confetti_kqweo8.webp")
    repeat;
  > h1 {
    color: $white;
    color: none;
    font-size: $xl;
    text-align: center;
    margin-bottom: $xl;
    position: relative;
    -webkit-text-stroke: 3px $yellow;
    text-stroke: 3px $yellow;

    svg {
      fill: $yellow;
      position: absolute;
      top: -0.2em;
      right: -0.6em;
      transform: rotate(30deg);
    }
  }
}
