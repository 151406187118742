@use "styles" as *;

.about {
  width: 100%;
  background-image: url("https://res.cloudinary.com/dftuawd1d/image/upload/v1675417438/harang/sky_fgapz7.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: $xxl;
  padding-bottom: $xxl;
  @include tablet() {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
}

.inner {
  @extend %center;
  max-width: 66rem;
  background: transparent;
  z-index: 1;
}
.desc {
  @extend %box-shadow;
  padding: $md;
  overflow: hidden;
  backdrop-filter: blur(2px);
  border-radius: $xs;
  background: rgba(255, 255, 255, 0.2);
  margin-bottom: $xl;
  strong {
    font-weight: 700;
  }
}

.seniors {
  @extend %noselect;
  display: flex;
  overflow: auto;
  width: 100%;
  li {
    width: 22rem;
    flex-shrink: 0;
    padding: $xs;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.senior_name {
  margin-top: $xs;
  font-size: $sm;
  font-weight: 700;
  > span {
    margin-right: 0.4em;
    font-size: $xs;
    font-weight: 500;
  }
}
.senior_desc {
  word-break: keep-all;
  font-size: 1.4rem;
  text-align: center;
}
