@use "styles" as *;

.youth_day {
  margin-top: $lg;
  padding: $md;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $lg;
  > * {
    flex: 1;
    width: 100%;
    max-width: 66rem;
  }
  h2 {
    font-size: $lg;
    margin-bottom: $xl;
  }
  @include desktop() {
    padding: $lg $xl;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: $xxl;
    &__left {
      max-width: 80rem;
    }
    &__right {
      max-width: 60rem;
    }
  }
}

.term_box {
  margin-top: 1rem;
  border: 1px solid $gray;
  padding: $xs;
  border-radius: 1rem;

  > p {
    font-weight: 500;
    margin-bottom: 6px;
  }
  > ul {
    font-size: 1.8rem;
  }
}
