@use "styles" as *;

.section_title {
  width: 100%;
  text-align: center;
  margin: 0 auto $xl;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main {
  word-break: keep-all;
  font-size: 3.4rem;
  font-weight: 700;
  @include tablet() {
    font-size: $xl;
  }
}

.sub {
  word-break: keep-all;
  font-size: $sm;
  font-weight: 500;
  font-size: 2.2rem;
  width: 100%;
  max-width: 66rem;
  margin-top: $xs;
  text-align: center;
  @include tablet() {
    font-size: 2.4rem;
  }
  strong {
    color: $blue;
    font-weight: 500;
  }
  span {
    display: inline-block;
  }
}
