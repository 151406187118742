@use "styles" as *;

.logo {
  cursor: pointer;
  height: max-content;
  overflow: hidden;
  svg {
    width: 100%;
    height: auto;
  }
}

.blue {
  g {
    fill: $blue;
  }
}

.white {
  g {
    fill: $white;
  }
}
