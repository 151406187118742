@use "../variables" as *;
@use "../mixins";

input:not([type="radio"]):not([type="checkbox"]),
select {
  padding: $xs $md;
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 1rem;
  border: none;
  transition: all 0.2s;
  background: $pale;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px $pale inset;
    -webkit-text-fill-color: $black;
  }
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px $pale inset;
    -webkit-text-fill-color: $black;
  }
  &:focus {
    outline: none;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  }
  &::-webkit-input-placeholder {
    color: $gray;
  }
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right $md center;
  background-size: 1em;
  color: inherit;
}

.radio_group {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  @include mixins.desktop() {
    gap: $xs;
  }
  height: max-content;
  width: 100%;
}
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="radio"] + label {
  -webkit-tap-highlight-color: transparent;
  padding: 1rem $sm;
  border-radius: 100rem;
  border: 1px solid $blue;
  cursor: pointer;
  transition: all 0.12s;
  display: inline-block;
  user-select: none;
}
input[type="radio"]:checked + label {
  background: $blue;
  color: $white;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}

.checkbox_group {
  display: flex;
  gap: 1rem 3rem;
  flex-wrap: wrap;
  width: 100%;
}

input[type="checkbox"] {
  margin-right: 10px;
}
input[type="checkbox"] + label {
  cursor: pointer;
  transition: all 0.12s;
  display: inline-block;
}

textarea {
  width: 100%;
  border-radius: 1rem;
  padding: $xs $md;
  resize: none;
  background: $pale;

  &:focus {
    outline: none;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  }
}
