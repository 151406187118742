*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
  cursor: pointer;
  border: none;
  background: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
}

// #root,
// #__next {
//   isolation: isolate;
// }

a {
  text-decoration: none;
  color: inherit;
}
