$black: #333;
$blue: #01a9c1;
$ivory: #fcfaf4;
$pale: #f4f5f5;
$red: #fe460d;
$pink: #ff7aa8;
$light-purple: #d3bef9;
$light-green: #e1fcff;
$white: #ffffff;
$gray: gray;
$yellow: #f8b846;

$mobile-bp: calc(375em / 16);
$tablet-bp: calc(768em / 16);
$desktop-bp: calc(1280em / 16);

$max-width: calc(1000em / 16);

$xs: 1.8rem;
$sm: 2rem;
$md: 3rem;
$lg: 4rem;
$xl: 6rem;
$xxl: 8rem;

$z-index: (
  "floating": 10,
);
